import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M3914 6889 c-42 -12 -101 -78 -112 -126 -6 -23 -7 -301 -3 -723 l7
-685 4 640 c3 541 5 600 11 380 3 -143 10 -286 14 -317 l8 -58 42 0 43 0 -5
-160 -6 -160 41 0 c41 0 41 0 45 43 2 23 2 -49 -1 -160 l-5 -203 41 0 41 0 3
43 c2 23 2 -31 -1 -120 l-4 -163 42 0 c47 0 43 22 41 -202 l-1 -126 83 -7 c46
-3 305 -5 577 -3 l494 3 20 26 c18 25 18 32 8 94 -18 100 -62 176 -133 230
-25 19 -83 42 -138 55 -30 7 -61 14 -68 16 -9 3 -12 27 -10 96 l3 93 55 -3
c66 -4 177 -35 228 -64 59 -34 140 -118 177 -183 36 -63 68 -173 80 -270 8
-62 34 -99 63 -91 9 2 82 8 161 11 180 9 186 13 176 113 -13 131 -26 198 -59
293 -82 242 -249 423 -483 523 -102 43 -181 64 -305 78 l-98 12 0 68 c0 39 4
68 10 68 29 0 206 -33 275 -51 333 -87 587 -308 719 -626 47 -114 86 -287 86
-383 0 -25 7 -55 16 -68 15 -21 21 -22 188 -22 143 0 176 3 186 15 10 12 10
42 1 146 -19 224 -79 445 -168 624 -35 71 -89 161 -108 181 -5 6 -34 42 -64
80 -151 191 -417 360 -701 443 -122 36 -289 67 -395 74 l-40 2 -3 59 c-2 33 1
62 7 65 6 4 64 -1 129 -10 343 -50 625 -163 865 -347 89 -67 230 -216 302
-317 117 -164 220 -390 264 -581 12 -49 24 -98 26 -109 3 -11 14 -87 25 -169
11 -93 24 -152 32 -158 19 -13 339 0 357 15 11 9 12 33 7 119 -24 397 -143
777 -338 1079 -132 203 -313 387 -508 515 -41 27 -77 51 -80 54 -17 17 -232
123 -320 158 -109 43 -253 88 -344 107 -44 9 -82 18 -86 20 -3 2 -60 11 -125
19 -97 13 -226 16 -690 15 -313 0 -584 -5 -601 -10z m-44 -53 c0 -2 -8 -10
-17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-51 -100 c-9 -8 -10 -7 -5
7 3 10 7 26 7 35 1 9 4 6 6 -7 3 -13 -1 -28 -8 -35z m-2 -78 c-2 -13 -4 -3 -4
22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m20 -320 c-2 -13 -4 -3 -4 22 0 25 2 35
4 23 2 -13 2 -33 0 -45z"/>
          <path d="M5071 4575 c-54 -17 -108 -68 -121 -115 -13 -47 -14 -46 23 -48 30
-2 35 2 51 38 24 55 55 73 124 73 88 0 131 -34 134 -106 2 -70 -48 -113 -140
-119 -29 -1 -52 -6 -52 -10 0 -5 0 -19 0 -33 0 -22 4 -25 39 -25 50 0 124 -31
145 -61 19 -27 21 -106 5 -142 -6 -14 -28 -34 -49 -46 -69 -39 -158 -19 -202
45 -15 21 -27 45 -28 52 0 16 -70 16 -70 0 0 -36 35 -96 73 -124 74 -54 171
-68 247 -33 57 26 77 44 102 93 41 79 2 187 -80 226 -38 18 -40 27 -9 39 61
23 105 114 87 180 -28 107 -148 157 -279 116z"/>
          <path d="M4313 4445 c4 -129 4 -134 -14 -124 -11 5 -19 14 -19 19 0 6 -4 10
-9 10 -16 0 -9 -43 11 -69 10 -13 18 -29 18 -36 0 -7 6 -15 14 -18 11 -4 14
14 15 91 1 53 4 98 7 101 2 2 5 -56 6 -130 2 -96 3 -111 5 -51 7 151 19 84 21
-115 2 -149 -1 -199 -10 -205 -9 -5 -8 -8 5 -8 16 0 17 23 17 335 l0 335 -36
0 -36 0 5 -135z m40 117 c-6 -4 -13 -23 -15 -42 l-3 -35 -6 43 c-4 37 -2 42
16 42 12 0 16 -3 8 -8z m-16 -114 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m-40 -136 c7 -4 14 -21 17 -37 2 -17 1 -23 -1 -15 -3 8 -8 17 -12
20 -31 24 -34 51 -4 32z"/>
          <path d="M5440 4235 l0 -327 173 4 c171 3 172 3 233 35 119 62 179 169 172
303 -8 131 -80 236 -194 280 -50 20 -83 24 -221 28 l-163 4 0 -327z m312 176
c74 -25 113 -97 106 -196 -10 -120 -73 -175 -200 -175 l-68 0 0 193 0 193 64
-2 c35 -1 79 -7 98 -13z"/>
          <path d="M6699 4548 c-1 -2 -2 -28 -3 -58 -1 -55 -1 -55 -34 -58 l-33 -3 3
-67 c3 -59 5 -67 23 -68 11 -1 25 -2 31 -3 7 -1 12 -49 13 -143 2 -113 6 -149
19 -172 25 -43 77 -66 158 -69 l69 -2 3 67 3 67 -48 3 -48 3 -3 122 -3 122 48
3 48 3 3 68 3 67 -51 0 -50 0 0 60 0 60 -75 0 c-41 0 -76 -1 -76 -2z"/>
          <path d="M7170 4429 c-85 -16 -135 -56 -173 -139 -28 -63 -28 -188 0 -250 24
-53 75 -106 122 -126 67 -28 162 -13 213 34 l27 24 3 -33 3 -34 78 -3 77 -3 0
266 0 265 -80 0 -80 0 0 -37 0 -36 -22 20 c-49 44 -111 63 -168 52z m152 -168
c31 -28 33 -33 33 -96 0 -64 -1 -68 -37 -101 -42 -38 -72 -43 -119 -19 -82 43
-92 177 -17 229 38 27 103 21 140 -13z"/>
          <path d="M6187 4416 c-15 -7 -45 -30 -67 -51 -100 -98 -99 -293 2 -401 75 -80
207 -85 285 -12 l23 21 0 -30 0 -31 76 -4 c42 -2 78 -1 80 1 2 2 4 121 4 263
l0 259 -77 -3 -78 -3 -3 -32 c-2 -18 -7 -33 -13 -33 -5 0 -9 4 -9 9 0 5 -20
21 -44 35 -50 29 -129 34 -179 12z m208 -156 c56 -59 43 -173 -25 -213 -54
-32 -110 -18 -149 38 -33 49 -27 132 14 175 25 26 36 30 80 30 44 0 55 -4 80
-30z"/>
          <path d="M4083 4403 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path d="M4440 4403 c0 -11 38 -119 49 -141 11 -21 34 -76 51 -122 7 -19 30
-79 52 -133 l41 -98 39 3 39 3 65 165 c36 91 76 190 89 220 13 30 27 68 31 83
6 27 5 28 -27 25 -37 -3 -33 3 -109 -206 -22 -62 -37 -119 -34 -125 4 -7 3 -8
-2 -4 -5 5 -16 -13 -25 -40 -14 -44 -42 -66 -45 -35 -1 6 -2 17 -3 23 0 5 -4
8 -8 6 -5 -3 -9 5 -9 16 -1 12 -31 99 -66 192 -64 167 -66 170 -97 173 -17 2
-31 -1 -31 -5z m40 -38 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5
-8 10 -19 10 -25z m7 -52 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0
-30z m71 -168 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m52 -70
c14 -18 13 -19 -1 -8 -9 7 -19 10 -23 6 -4 -4 -4 1 0 10 3 10 7 17 8 15 1 -2
8 -12 16 -23z m70 -125 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z"/>
          <path d="M4196 4392 c-3 -5 6 -14 21 -21 35 -16 21 -26 -18 -12 -18 6 -53 10
-78 10 -43 -1 -42 -2 17 -10 35 -4 74 -14 86 -22 20 -14 22 -13 21 9 0 24 -40
61 -49 46z"/>
          <path d="M4098 4383 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
          <path d="M4040 4350 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
          <path d="M4312 4163 c0 -50 -21 -115 -41 -127 -6 -4 -11 -13 -11 -19 1 -17 50
41 50 59 0 9 5 12 11 8 8 -5 10 10 6 55 -6 76 -16 91 -15 24z"/>
          <path d="M3478 4103 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
          <path d="M4210 3979 c-27 -15 -30 -19 -14 -19 11 0 29 9 39 20 21 23 21 23
-25 -1z"/>
          <path d="M4267 3993 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
          <path d="M4311 3964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M4230 3955 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
